import React, { useEffect, useState } from 'react'

const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const match = (location().pathname.match(/thanks\/(\d+)_(.+)/) || [])
const eventId = match[1]
const token = match[2]

export default function Home() {
  useEffect(() => {
    window.location = `${window.location.origin}/event/${eventId}_${token}`
  }, [])
  return null
}
